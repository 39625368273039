var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function (e, t) {
  exports = t();
}(window, function () {
  return function (e) {
    var t = {};

    function n(r) {
      if (t[r]) return t[r].exports;
      var i = t[r] = {
        i: r,
        l: !1,
        exports: {}
      };
      return e[r].call(i.exports, i, i.exports, n), i.l = !0, i.exports;
    }

    return n.m = e, n.c = t, n.d = function (e, t, r) {
      n.o(e, t) || Object.defineProperty(e, t, {
        enumerable: !0,
        get: r
      });
    }, n.r = function (e) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    }, n.t = function (e, t) {
      if (1 & t && (e = n(e)), 8 & t) return e;
      if (4 & t && "object" == typeof e && e && e.__esModule) return e;
      var r = Object.create(null);
      if (n.r(r), Object.defineProperty(r, "default", {
        enumerable: !0,
        value: e
      }), 2 & t && "string" != typeof e) for (var i in e) n.d(r, i, function (t) {
        return e[t];
      }.bind(null, i));
      return r;
    }, n.n = function (e) {
      var t = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return n.d(t, "a", t), t;
    }, n.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, n.p = "/", n(n.s = 14);
  }([function (e, t, n) {
    var r = n(5),
        i = n(6),
        o = n(7),
        a = n(9);

    e.exports = function (e, t) {
      return r(e) || i(e, t) || o(e, t) || a();
    };
  }, function (e, t) {
    function n(t) {
      return "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? e.exports = n = function (e) {
        return typeof e;
      } : e.exports = n = function (e) {
        return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
      }, n(t);
    }

    e.exports = n;
  }, function (e, t) {
    e.exports = function (e, t) {
      if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
    };
  }, function (e, t) {
    function n(e, t) {
      for (var n = 0; n < t.length; n++) {
        var r = t[n];
        r.enumerable = r.enumerable || !1, r.configurable = !0, "value" in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
      }
    }

    e.exports = function (e, t, r) {
      return t && n(e.prototype, t), r && n(e, r), e;
    };
  }, function (e, t) {
    function n(e, t, n) {
      var r, i, o, a, l;

      function c() {
        var s = Date.now() - a;
        s < t && s >= 0 ? r = setTimeout(c, t - s) : (r = null, n || (l = e.apply(o, i), o = i = null));
      }

      null == t && (t = 100);

      var s = function () {
        o = this || _global, i = arguments, a = Date.now();
        var s = n && !r;
        return r || (r = setTimeout(c, t)), s && (l = e.apply(o, i), o = i = null), l;
      };

      return s.clear = function () {
        r && (clearTimeout(r), r = null);
      }, s.flush = function () {
        r && (l = e.apply(o, i), o = i = null, clearTimeout(r), r = null);
      }, s;
    }

    n.debounce = n, e.exports = n;
  }, function (e, t) {
    e.exports = function (e) {
      if (Array.isArray(e)) return e;
    };
  }, function (e, t) {
    e.exports = function (e, t) {
      if ("undefined" != typeof Symbol && Symbol.iterator in Object(e)) {
        var n = [],
            r = !0,
            i = !1,
            o = void 0;

        try {
          for (var a, l = e[Symbol.iterator](); !(r = (a = l.next()).done) && (n.push(a.value), !t || n.length !== t); r = !0);
        } catch (e) {
          i = !0, o = e;
        } finally {
          try {
            r || null == l.return || l.return();
          } finally {
            if (i) throw o;
          }
        }

        return n;
      }
    };
  }, function (e, t, n) {
    var r = n(8);

    e.exports = function (e, t) {
      if (e) {
        if ("string" == typeof e) return r(e, t);
        var n = Object.prototype.toString.call(e).slice(8, -1);
        return "Object" === n && e.constructor && (n = e.constructor.name), "Map" === n || "Set" === n ? Array.from(n) : "Arguments" === n || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n) ? r(e, t) : void 0;
      }
    };
  }, function (e, t) {
    e.exports = function (e, t) {
      (null == t || t > e.length) && (t = e.length);

      for (var n = 0, r = new Array(t); n < t; n++) r[n] = e[n];

      return r;
    };
  }, function (e, t) {
    e.exports = function () {
      throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
    };
  }, function (e, t, n) {
    var r = n(11),
        i = n(12);
    "string" == typeof (i = i.__esModule ? i.default : i) && (i = [[e.i, i, ""]]);
    var o = {
      insert: "head",
      singleton: !1
    };
    r(i, o);
    e.exports = i.locals || {};
  }, function (e, t, n) {
    "use strict";

    var r,
        i = function () {
      return void 0 === r && (r = Boolean(window && document && document.all && !window.atob)), r;
    },
        o = function () {
      var e = {};
      return function (t) {
        if (void 0 === e[t]) {
          var n = document.querySelector(t);
          if (window.HTMLIFrameElement && n instanceof window.HTMLIFrameElement) try {
            n = n.contentDocument.head;
          } catch (e) {
            n = null;
          }
          e[t] = n;
        }

        return e[t];
      };
    }(),
        a = [];

    function l(e) {
      for (var t = -1, n = 0; n < a.length; n++) if (a[n].identifier === e) {
        t = n;
        break;
      }

      return t;
    }

    function c(e, t) {
      for (var n = {}, r = [], i = 0; i < e.length; i++) {
        var o = e[i],
            c = t.base ? o[0] + t.base : o[0],
            s = n[c] || 0,
            d = "".concat(c, " ").concat(s);
        n[c] = s + 1;
        var u = l(d),
            h = {
          css: o[1],
          media: o[2],
          sourceMap: o[3]
        };
        -1 !== u ? (a[u].references++, a[u].updater(h)) : a.push({
          identifier: d,
          updater: b(h, t),
          references: 1
        }), r.push(d);
      }

      return r;
    }

    function s(e) {
      var t = document.createElement("style"),
          r = e.attributes || {};

      if (void 0 === r.nonce) {
        var i = n.nc;
        i && (r.nonce = i);
      }

      if (Object.keys(r).forEach(function (e) {
        t.setAttribute(e, r[e]);
      }), "function" == typeof e.insert) e.insert(t);else {
        var a = o(e.insert || "head");
        if (!a) throw new Error("Couldn't find a style target. This probably means that the value for the 'insert' parameter is invalid.");
        a.appendChild(t);
      }
      return t;
    }

    var d,
        u = (d = [], function (e, t) {
      return d[e] = t, d.filter(Boolean).join("\n");
    });

    function h(e, t, n, r) {
      var i = n ? "" : r.media ? "@media ".concat(r.media, " {").concat(r.css, "}") : r.css;
      if (e.styleSheet) e.styleSheet.cssText = u(t, i);else {
        var o = document.createTextNode(i),
            a = e.childNodes;
        a[t] && e.removeChild(a[t]), a.length ? e.insertBefore(o, a[t]) : e.appendChild(o);
      }
    }

    function m(e, t, n) {
      var r = n.css,
          i = n.media,
          o = n.sourceMap;
      if (i ? e.setAttribute("media", i) : e.removeAttribute("media"), o && btoa && (r += "\n/*# sourceMappingURL=data:application/json;base64,".concat(btoa(unescape(encodeURIComponent(JSON.stringify(o)))), " */")), e.styleSheet) e.styleSheet.cssText = r;else {
        for (; e.firstChild;) e.removeChild(e.firstChild);

        e.appendChild(document.createTextNode(r));
      }
    }

    var f = null,
        p = 0;

    function b(e, t) {
      var n, r, i;

      if (t.singleton) {
        var o = p++;
        n = f || (f = s(t)), r = h.bind(null, n, o, !1), i = h.bind(null, n, o, !0);
      } else n = s(t), r = m.bind(null, n, t), i = function () {
        !function (e) {
          if (null === e.parentNode) return !1;
          e.parentNode.removeChild(e);
        }(n);
      };

      return r(e), function (t) {
        if (t) {
          if (t.css === e.css && t.media === e.media && t.sourceMap === e.sourceMap) return;
          r(e = t);
        } else i();
      };
    }

    e.exports = function (e, t) {
      (t = t || {}).singleton || "boolean" == typeof t.singleton || (t.singleton = i());
      var n = c(e = e || [], t);
      return function (e) {
        if (e = e || [], "[object Array]" === Object.prototype.toString.call(e)) {
          for (var r = 0; r < n.length; r++) {
            var i = l(n[r]);
            a[i].references--;
          }

          for (var o = c(e, t), s = 0; s < n.length; s++) {
            var d = l(n[s]);
            0 === a[d].references && (a[d].updater(), a.splice(d, 1));
          }

          n = o;
        }
      };
    };
  }, function (e, t, n) {
    (t = n(13)(!1)).push([e.i, ".embed-tool--loading .embed-tool__caption {\n      display: none;\n    }\n\n    .embed-tool--loading .embed-tool__preloader {\n      display: block;\n    }\n\n    .embed-tool--loading .embed-tool__content {\n      display: none;\n    }\n  .embed-tool__preloader {\n    display: none;\n    position: relative;\n    height: 200px;\n    box-sizing: border-box;\n    border-radius: 5px;\n    border: 1px solid #e6e9eb;\n  }\n  .embed-tool__preloader::before {\n      content: '';\n      position: absolute;\n      z-index: 3;\n      left: 50%;\n      top: 50%;\n      width: 30px;\n      height: 30px;\n      margin-top: -25px;\n      margin-left: -15px;\n      border-radius: 50%;\n      border: 2px solid #cdd1e0;\n      border-top-color: #388ae5;\n      box-sizing: border-box;\n      animation: embed-preloader-spin 2s infinite linear;\n    }\n  .embed-tool__url {\n    position: absolute;\n    bottom: 20px;\n    left: 50%;\n    transform: translateX(-50%);\n    max-width: 250px;\n    color: #7b7e89;\n    font-size: 11px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n  .embed-tool__content {\n    width: 100%;\n  }\n  .embed-tool__caption {\n    margin-top: 7px;\n  }\n  .embed-tool__caption[contentEditable=true][data-placeholder]::before{\n      position: absolute;\n      content: attr(data-placeholder);\n      color: #707684;\n      font-weight: normal;\n      opacity: 0;\n    }\n  .embed-tool__caption[contentEditable=true][data-placeholder]:empty::before {\n         opacity: 1;\n      }\n  .embed-tool__caption[contentEditable=true][data-placeholder]:empty:focus::before {\n        opacity: 0;\n      }\n\n@keyframes embed-preloader-spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n", ""]), e.exports = t;
  }, function (e, t, n) {
    "use strict";

    e.exports = function (e) {
      var t = [];
      return t.toString = function () {
        return this.map(function (t) {
          var n = function (e, t) {
            var n = e[1] || "",
                r = e[3];
            if (!r) return n;

            if (t && "function" == typeof btoa) {
              var i = (a = r, l = btoa(unescape(encodeURIComponent(JSON.stringify(a)))), c = "sourceMappingURL=data:application/json;charset=utf-8;base64,".concat(l), "/*# ".concat(c, " */")),
                  o = r.sources.map(function (e) {
                return "/*# sourceURL=".concat(r.sourceRoot || "").concat(e, " */");
              });
              return [n].concat(o).concat([i]).join("\n");
            }

            var a, l, c;
            return [n].join("\n");
          }(t, e);

          return t[2] ? "@media ".concat(t[2], " {").concat(n, "}") : n;
        }).join("");
      }, t.i = function (e, n, r) {
        "string" == typeof e && (e = [[null, e, ""]]);
        var i = {};
        if (r) for (var o = 0; o < (this || _global).length; o++) {
          var a = (this || _global)[o][0];
          null != a && (i[a] = !0);
        }

        for (var l = 0; l < e.length; l++) {
          var c = [].concat(e[l]);
          r && i[c[0]] || (n && (c[2] ? c[2] = "".concat(n, " and ").concat(c[2]) : c[2] = n), t.push(c));
        }
      }, t;
    };
  }, function (e, t, n) {
    "use strict";

    n.r(t), n.d(t, "default", function () {
      return m;
    });

    var r = n(1),
        i = n.n(r),
        o = n(0),
        a = n.n(o),
        l = n(2),
        c = n.n(l),
        s = n(3),
        d = n.n(s),
        u = {
      vimeo: {
        regex: /(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
        embedUrl: "https://player.vimeo.com/video/<%= remote_id %>?title=0&byline=0",
        html: "<iframe style=\"width:100%;\" height=\"320\" frameborder=\"0\"></iframe>",
        height: 320,
        width: 580
      },
      youtube: {
        regex: /(?:https?:\/\/)?(?:www\.)?(?:(?:youtu\.be\/)|(?:youtube\.com)\/(?:v\/|u\/\w\/|embed\/|watch))(?:(?:\?v=)?([^#&?=]*))?((?:[?&]\w*=\w*)*)/,
        embedUrl: "https://www.youtube.com/embed/<%= remote_id %>",
        html: "<iframe style=\"width:100%;\" height=\"320\" frameborder=\"0\" allowfullscreen></iframe>",
        height: 320,
        width: 580,
        id: function (e) {
          var t = a()(e, 2),
              n = t[0],
              r = t[1];
          if (!r && n) return n;
          var i = {
            start: "start",
            end: "end",
            t: "start",
            time_continue: "start",
            list: "list"
          };
          return r = r.slice(1).split("&").map(function (e) {
            var t = e.split("="),
                r = a()(t, 2),
                o = r[0],
                l = r[1];
            return n || "v" !== o ? i[o] ? "".concat(i[o], "=").concat(l) : null : (n = l, null);
          }).filter(function (e) {
            return !!e;
          }), n + "?" + r.join("&");
        }
      },
      coub: {
        regex: /https?:\/\/coub\.com\/view\/([^\/\?\&]+)/,
        embedUrl: "https://coub.com/embed/<%= remote_id %>",
        html: "<iframe style=\"width:100%;\" height=\"320\" frameborder=\"0\" allowfullscreen></iframe>",
        height: 320,
        width: 580
      },
      vine: {
        regex: /https?:\/\/vine\.co\/v\/([^\/\?\&]+)/,
        embedUrl: "https://vine.co/v/<%= remote_id %>/embed/simple/",
        html: "<iframe style=\"width:100%;\" height=\"320\" frameborder=\"0\" allowfullscreen></iframe>",
        height: 320,
        width: 580
      },
      imgur: {
        regex: /https?:\/\/(?:i\.)?imgur\.com.*\/([a-zA-Z0-9]+)(?:\.gifv)?/,
        embedUrl: "http://imgur.com/<%= remote_id %>/embed",
        html: "<iframe allowfullscreen=\"true\" scrolling=\"no\" id=\"imgur-embed-iframe-pub-<%= remote_id %>\" class=\"imgur-embed-iframe-pub\" style=\"height: 500px; width: 100%; border: 1px solid #000\"></iframe>",
        height: 500,
        width: 540
      },
      gfycat: {
        regex: /https?:\/\/gfycat\.com(?:\/detail)?\/([a-zA-Z]+)/,
        embedUrl: "https://gfycat.com/ifr/<%= remote_id %>",
        html: "<iframe frameborder='0' scrolling='no' style=\"width:100%;\" height='436' allowfullscreen ></iframe>",
        height: 436,
        width: 580
      },
      "twitch-channel": {
        regex: /https?:\/\/www\.twitch\.tv\/([^\/\?\&]*)\/?$/,
        embedUrl: "https://player.twitch.tv/?channel=<%= remote_id %>",
        html: "<iframe frameborder=\"0\" allowfullscreen=\"true\" scrolling=\"no\" height=\"366\" style=\"width:100%;\"></iframe>",
        height: 366,
        width: 600
      },
      "twitch-video": {
        regex: /https?:\/\/www\.twitch\.tv\/(?:[^\/\?\&]*\/v|videos)\/([0-9]*)/,
        embedUrl: "https://player.twitch.tv/?video=v<%= remote_id %>",
        html: "<iframe frameborder=\"0\" allowfullscreen=\"true\" scrolling=\"no\" height=\"366\" style=\"width:100%;\"></iframe>",
        height: 366,
        width: 600
      },
      "yandex-music-album": {
        regex: /https?:\/\/music\.yandex\.ru\/album\/([0-9]*)\/?$/,
        embedUrl: "https://music.yandex.ru/iframe/#album/<%= remote_id %>/",
        html: "<iframe frameborder=\"0\" style=\"border:none;width:540px;height:400px;\" style=\"width:100%;\" height=\"400\"></iframe>",
        height: 400,
        width: 540
      },
      "yandex-music-track": {
        regex: /https?:\/\/music\.yandex\.ru\/album\/([0-9]*)\/track\/([0-9]*)/,
        embedUrl: "https://music.yandex.ru/iframe/#track/<%= remote_id %>/",
        html: "<iframe frameborder=\"0\" style=\"border:none;width:540px;height:100px;\" style=\"width:100%;\" height=\"100\"></iframe>",
        height: 100,
        width: 540,
        id: function (e) {
          return e.join("/");
        }
      },
      "yandex-music-playlist": {
        regex: /https?:\/\/music\.yandex\.ru\/users\/([^\/\?\&]*)\/playlists\/([0-9]*)/,
        embedUrl: "https://music.yandex.ru/iframe/#playlist/<%= remote_id %>/show/cover/description/",
        html: "<iframe frameborder=\"0\" style=\"border:none;width:540px;height:400px;\" width=\"540\" height=\"400\"></iframe>",
        height: 400,
        width: 540,
        id: function (e) {
          return e.join("/");
        }
      },
      codepen: {
        regex: /https?:\/\/codepen\.io\/([^\/\?\&]*)\/pen\/([^\/\?\&]*)/,
        embedUrl: "https://codepen.io/<%= remote_id %>?height=300&theme-id=0&default-tab=css,result&embed-version=2",
        html: "<iframe height='300' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='width: 100%;'></iframe>",
        height: 300,
        width: 600,
        id: function (e) {
          return e.join("/embed/");
        }
      },
      instagram: {
        regex: /https?:\/\/www\.instagram\.com\/p\/([^\/\?\&]+)\/?/,
        embedUrl: "https://www.instagram.com/p/<%= remote_id %>/embed",
        html: "<iframe width=\"400\" height=\"505\" style=\"margin: 0 auto;\" frameborder=\"0\" scrolling=\"no\" allowtransparency=\"true\"></iframe>",
        height: 505,
        width: 400
      },
      twitter: {
        regex: /^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+?.*)?$/,
        embedUrl: "https://twitframe.com/show?url=https://twitter.com/<%= remote_id %>",
        html: "<iframe width=\"600\" height=\"600\" style=\"margin: 0 auto;\" frameborder=\"0\" scrolling=\"no\" allowtransparency=\"true\"></iframe>",
        height: 300,
        width: 600,
        id: function (e) {
          return e.join("/status/");
        }
      },
      pinterest: {
        regex: /https?:\/\/([^\/\?\&]*).pinterest.com\/pin\/([^\/\?\&]*)\/?$/,
        embedUrl: "https://assets.pinterest.com/ext/embed.html?id=<%= remote_id %>",
        html: "<iframe scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='width: 100%; min-height: 400px; max-height: 1000px;'></iframe>",
        id: function (e) {
          return e[1];
        }
      },
      facebook: {
        regex: /https?:\/\/www.facebook.com\/([^\/\?\&]*)\/(.*)/,
        embedUrl: "https://www.facebook.com/plugins/post.php?href=https://www.facebook.com/<%= remote_id %>&width=500",
        html: "<iframe scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='width: 100%; min-height: 500px; max-height: 1000px;'></iframe>",
        id: function (e) {
          return e.join("/");
        }
      },
      aparat: {
        regex: /(?:http[s]?:\/\/)?(?:www.)?aparat\.com\/v\/([^\/\?\&]+)\/?/,
        embedUrl: "https://www.aparat.com/video/video/embed/videohash/<%= remote_id %>/vt/frame",
        html: "<iframe width=\"600\" height=\"300\" style=\"margin: 0 auto;\" frameborder=\"0\" scrolling=\"no\" allowtransparency=\"true\"></iframe>",
        height: 300,
        width: 600
      },
      miro: {
        regex: /https:\/\/miro.com\/\S+(\S{12})\/(\S+)?/,
        embedUrl: "https://miro.com/app/live-embed/<%= remote_id %>",
        html: "<iframe width=\"700\" height=\"500\" style=\"margin: 0 auto;\" allowFullScreen frameBorder=\"0\" scrolling=\"no\"></iframe>"
      }
    },
        h = (n(10), n(4)),
        m = function () {
      function e(t) {
        var n = t.data,
            r = t.api,
            i = t.readOnly;
        c()(this || _global, e), (this || _global).api = r, (this || _global)._data = {}, (this || _global).element = null, (this || _global).readOnly = i, (this || _global).data = n;
      }

      return d()(e, [{
        key: "render",
        value: function () {
          var t = this || _global;

          if (!(this || _global).data.service) {
            var n = document.createElement("div");
            return (this || _global).element = n, n;
          }

          var r = e.services[(this || _global).data.service].html,
              i = document.createElement("div"),
              o = document.createElement("div"),
              a = document.createElement("template"),
              l = this.createPreloader();
          i.classList.add((this || _global).CSS.baseClass, (this || _global).CSS.container, (this || _global).CSS.containerLoading), o.classList.add((this || _global).CSS.input, (this || _global).CSS.caption), i.appendChild(l), o.contentEditable = !(this || _global).readOnly, o.dataset.placeholder = (this || _global).api.i18n.t("Enter a caption"), o.innerHTML = (this || _global).data.caption || "", a.innerHTML = r, a.content.firstChild.setAttribute("src", (this || _global).data.embed), a.content.firstChild.classList.add((this || _global).CSS.content);
          var c = this.embedIsReady(i);
          return i.appendChild(a.content.firstChild), i.appendChild(o), c.then(function () {
            i.classList.remove(t.CSS.containerLoading);
          }), (this || _global).element = i, i;
        }
      }, {
        key: "createPreloader",
        value: function () {
          var e = document.createElement("preloader"),
              t = document.createElement("div");
          return t.textContent = (this || _global).data.source, e.classList.add((this || _global).CSS.preloader), t.classList.add((this || _global).CSS.url), e.appendChild(t), e;
        }
      }, {
        key: "save",
        value: function () {
          return (this || _global).data;
        }
      }, {
        key: "onPaste",
        value: function (t) {
          var n = t.detail,
              r = n.key,
              i = n.data,
              o = e.services[r],
              a = o.regex,
              l = o.embedUrl,
              c = o.width,
              s = o.height,
              d = o.id,
              u = void 0 === d ? function (e) {
            return e.shift();
          } : d,
              h = a.exec(i).slice(1),
              m = l.replace(/<%= remote_id %>/g, u(h));
          (this || _global).data = {
            service: r,
            source: i,
            embed: m,
            width: c,
            height: s
          };
        }
      }, {
        key: "embedIsReady",
        value: function (e) {
          var t = null;
          return new Promise(function (n, r) {
            (t = new MutationObserver(Object(h.debounce)(n, 450))).observe(e, {
              childList: !0,
              subtree: !0
            });
          }).then(function () {
            t.disconnect();
          });
        }
      }, {
        key: "data",
        set: function (e) {
          if (!(e instanceof Object)) throw Error("Embed Tool data should be object");
          var t = e.service,
              n = e.source,
              r = e.embed,
              i = e.width,
              o = e.height,
              a = e.caption,
              l = void 0 === a ? "" : a;
          (this || _global)._data = {
            service: t || (this || _global).data.service,
            source: n || (this || _global).data.source,
            embed: r || (this || _global).data.embed,
            width: i || (this || _global).data.width,
            height: o || (this || _global).data.height,
            caption: l || (this || _global).data.caption || ""
          };
          var c = (this || _global).element;
          c && c.parentNode.replaceChild(this.render(), c);
        },
        get: function () {
          if ((this || _global).element) {
            var e = (this || _global).element.querySelector(".".concat((this || _global).api.styles.input));

            (this || _global)._data.caption = e ? e.innerHTML : "";
          }

          return (this || _global)._data;
        }
      }, {
        key: "CSS",
        get: function () {
          return {
            baseClass: (this || _global).api.styles.block,
            input: (this || _global).api.styles.input,
            container: "embed-tool",
            containerLoading: "embed-tool--loading",
            preloader: "embed-tool__preloader",
            caption: "embed-tool__caption",
            url: "embed-tool__url",
            content: "embed-tool__content"
          };
        }
      }], [{
        key: "prepare",
        value: function (t) {
          var n = t.config,
              r = (void 0 === n ? {} : n).services,
              o = void 0 === r ? {} : r,
              l = Object.entries(u),
              c = Object.entries(o).filter(function (e) {
            var t = a()(e, 2),
                n = (t[0], t[1]);
            return "boolean" == typeof n && !0 === n;
          }).map(function (e) {
            return a()(e, 1)[0];
          }),
              s = Object.entries(o).filter(function (e) {
            var t = a()(e, 2),
                n = (t[0], t[1]);
            return "object" === i()(n);
          }).filter(function (t) {
            var n = a()(t, 2),
                r = (n[0], n[1]);
            return e.checkServiceConfig(r);
          }).map(function (e) {
            var t = a()(e, 2),
                n = t[0],
                r = t[1];
            return [n, {
              regex: r.regex,
              embedUrl: r.embedUrl,
              html: r.html,
              height: r.height,
              width: r.width,
              id: r.id
            }];
          });
          c.length && (l = l.filter(function (e) {
            var t = a()(e, 1)[0];
            return c.includes(t);
          })), l = l.concat(s), e.services = l.reduce(function (e, t) {
            var n = a()(t, 2),
                r = n[0],
                i = n[1];
            return r in e ? (e[r] = Object.assign({}, e[r], i), e) : (e[r] = i, e);
          }, {}), e.patterns = l.reduce(function (e, t) {
            var n = a()(t, 2),
                r = n[0],
                i = n[1];
            return e[r] = i.regex, e;
          }, {});
        }
      }, {
        key: "checkServiceConfig",
        value: function (e) {
          var t = e.regex,
              n = e.embedUrl,
              r = e.html,
              i = e.height,
              o = e.width,
              a = e.id,
              l = t && t instanceof RegExp && n && "string" == typeof n && r && "string" == typeof r;
          return l = (l = (l = l && (void 0 === a || a instanceof Function)) && (void 0 === i || Number.isFinite(i))) && (void 0 === o || Number.isFinite(o));
        }
      }, {
        key: "pasteConfig",
        get: function () {
          return {
            patterns: e.patterns
          };
        }
      }, {
        key: "isReadOnlySupported",
        get: function () {
          return !0;
        }
      }]), e;
    }();
  }]).default;
});
export default exports;
export const Embed = exports.Embed;